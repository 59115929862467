import dayjs from 'dayjs'
import React, { FC } from 'react'

import { Table } from 'Components'
import { DebugPanel } from 'Components/Debug'
import { Program } from 'Models'
import { ProgramTypes } from 'Pages/AnamnesisPage/types'
import { getAnamnesisDataSelector } from 'ReduxStore/anamnesis'
import { useAppSelector } from 'ReduxStore/hooks'
import { Calculator } from 'Utils'

export interface Props {
  currentGoalEnergy: number
  currentWeight: number | null
  currentBellyCircumference: number | null
  startWeight: number | null
  startBelly: number | null
  currentProgram?: Program | null
  programBelly: number | null
  height: number | null
}

const InfoPanel: FC<Props> = (props: Props) => {
  const {
    currentGoalEnergy,
    currentWeight,
    currentBellyCircumference,
    startWeight,
    startBelly,
    currentProgram,
    programBelly,
    height
  } = props

  // Energy
  const EnergySection = (): JSX.Element => (
    <div className="mb-5">
      <Table titleRow={['Kalorien', '']} rows={[['Kalorienniveau', Calculator.round(currentGoalEnergy, 0)]]} />
    </div>
  )

  // Check Weight
  const diffeenceWeight = currentWeight && startWeight && (currentWeight - startWeight).toFixed(1)

  const WeightSection = (): JSX.Element | null =>
    currentWeight ? (
      <div className="mb-5">
        <Table
          titleRow={['Mein Gewicht', '']}
          rows={[
            ['Mein Gewicht bei der Anmeldung', `${startWeight} kg`],
            ['Mein aktuelles Gewicht', `${currentWeight} kg`],
            ['Veränderung des Gewichts', `${diffeenceWeight} kg`]
          ]}
        />
      </div>
    ) : null

  // Check Belly
  const diffeenceBelly = currentBellyCircumference && startBelly && (currentBellyCircumference - startBelly).toFixed(1)

  const BellySection = (): JSX.Element | null =>
    currentBellyCircumference ? (
      <div className="mb-5">
        <Table
          titleRow={['Bauchumfang', '']}
          rows={[
            ['Bauchumfang bei der Anmeldung', `${startBelly} cm`],
            ['Mein aktueller Bauchumfang', `${currentBellyCircumference} cm`],
            ['Veränderung des Bauchumfangs', `${diffeenceBelly} cm`]
          ]}
        />
      </div>
    ) : null

  // Check BMI
  const currentBMI = currentWeight && Calculator.calculateBMI(currentWeight, height).toFixed(1)
  const startBMI = startWeight && Calculator.calculateBMI(startWeight, height).toFixed(1)
  const differenceBMI = currentBMI && startBMI && (parseFloat(currentBMI) - parseFloat(startBMI)).toFixed(1)

  const BMISection = (): JSX.Element | null =>
    differenceBMI ? (
      <div className="mb-5">
        <Table
          titleRow={['BMI', '']}
          rows={[
            ['Mein BMI bei der Anmeldung', startBMI],
            ['Mein aktueller BMI', currentBMI],
            ['Veränderung des BMI', differenceBMI]
          ]}
        />
      </div>
    ) : null

  // Check Program
  const ProgramSection = (): JSX.Element | null => {
    const programType = useAppSelector(getAnamnesisDataSelector)
    let goalDate
    if (programType?.activeProgramType === ProgramTypes.KEEP_WEIGHT) {
      goalDate = '-'
    } else if (currentProgram?.goalDate) {
      goalDate = currentProgram && dayjs(currentProgram.goalDate).format('L')
    }

    const goalCaption =
      programType?.activeProgramType === ProgramTypes.KEEP_WEIGHT ? 'Gewicht halten' : 'Ziel der Gewichtsreduktion'

    const renderGoalDifference = (currentWeight: number | null, startValue: number): string => {
      const difference = (currentWeight || 0) - (startValue || 0)
      if (difference === 0) {
        return '-'
      }
      return (difference > 0 ? '+' : '') + difference.toFixed(1) + ' kg'
    }

    return currentProgram ? (
      <div className="mb-5">
        <Table
          titleRow={['Mein Ziel', '']}
          rows={[
            [goalCaption, `${currentProgram.goalValue} kg`],
            ['Differenz Ziel/aktuelles Gewicht', `${renderGoalDifference(currentWeight, currentProgram.goalValue)}`],
            ['Zieldatum', goalDate],
            ['Gewicht beim Programmstart', `${currentProgram.startValue} kg`],
            programBelly ? ['Bauchumfang beim Programmstart', `${programBelly} cm`] : []
          ]}
        />
      </div>
    ) : null
  }

  return (
    <>
      <DebugPanel>
        Props:
        <code>{JSON.stringify(props, null, 2)}</code>
      </DebugPanel>
      <EnergySection />
      <WeightSection />
      <BMISection />
      <BellySection />
      <ProgramSection />
    </>
  )
}

export default InfoPanel
